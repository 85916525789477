<template>
  <div>
    <v-card-title class="card-header fixed-card-title2">
      {{$t('add-medical-note-category')}}
      <v-spacer />
      <v-icon color="white" @click="closeDrawer">mdi-window-close</v-icon>
    </v-card-title>

    <v-col cols="12" lg="12" md="12" sm="12">
      <v-text-field
          outlined
          dense
          hide-details
          v-model="medicalNoteCategoryName"
          :label="$t('medical-note-category-name')"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="12" md="12" sm="12">
      <v-select
          outlined
          dense
          :items="medicalNoteTypes"
          item-text="longDescription"
          item-value="lowValue"
          v-model="selectedNoteType"
          :label="$t('select-reviews')"
          single-line
          hide-details
          class="mt-n3"
          :menu-props="{offsetY:true}"
      ></v-select>
    </v-col>
    <v-col cols="12" lg="12" md="12" sm="12">
      <v-textarea
          class="mt-n3"
          hide-details
          dense
          outlined
          no-resize
          :label="$t('medical-note-category-description')"
          v-model="medicalNoteCategoryDescription"
      ></v-textarea>
    </v-col>

    <v-col cols="12" lg="12" md="12" sm="12">
      <v-text-field
          outlined
          dense
          v-model="billingsTime"
          :label="$t('billings-time')"
          :rules="[value => /^([0-5]?[0-9]):([0-5][0-9])$/.test(value) || 'Format MM:SS']"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="12" md="12" sm="12">
      <v-data-table
          :key="tableKey"
          outlined
          dense
          v-model="selectedHospitalIds"
          :items="hospitals"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          :height="pageHeight(-150)"
          :headers="headers"
          fixed-header
          :loading="loading"
          show-select
          :footer-props="footerProps"
          :items-per-page="-1"
          class="mt-n6"
          hide-default-footer
      >
      </v-data-table>
    </v-col>

    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="save"
              class="save-button-style"
              :disabled="isSaveDisabled"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data () {
    return {
      selectedHospital: null,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      medicalNoteCategoryName: '',
      selectedNoteType: '',
      medicalNoteCategoryDescription: '',
      medicalNoteTypes: [],
      billingsTime: '',
      previousValue: '',
      toggle: false,
      tableKey: 0,
      selectedHospitalIds: [],
      loading: true,
    };
  },
  async mounted () {
    await this.getHospitals();
    await this.getAllVerifiers();
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      selectedVerifier: (state) => state.verifiers.selectedVerifier,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
    }),
    hospitals () {
      return this.$store.state?.hospitals?.hospitals || [];
    },
    isSaveDisabled () {
      return (
          !this.billingsTime ||
          !/^([0-5]?[0-9]):([0-5][0-9])$/.test(this.billingsTime) ||
              !this.medicalNoteCategoryName ||
              !this.selectedNoteType ||
              !this.medicalNoteCategoryDescription ||
              this.selectedHospitalIds.length === 0
      );
    },
    headers () {
      return [
        { text: this.$t('office-name'), value: 'hospitalName', class: '', align: 'start pl-4' },
      ];
    },
  },

  methods: {
    getCategoryMedicalNotesTemplates () {
      this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplate');
    },
    async getAllVerifiers () {
      await this.$store
          .dispatch('verifiers/getVerifiersForDomainMedicalNoteTypeCategory')
          .then((res) => {
            this.medicalNoteTypes = res;
          });
    },
    save () {
      const [minutes, seconds] = this.billingsTime.split(':').map(Number);
      const totalSeconds = minutes * 60 + seconds;

      switch (this.selectedNoteType) {
        case 0:
          this.selectedNoteType = 'MN-NORMAL-READING';
          break;
        case 1:
          this.selectedNoteType = 'MN-ABNORMAL-READING';
          break;
        case 2:
          this.selectedNoteType = 'MN-NO-READING';
          break;
        case 3:
          this.selectedNoteType = 'MN-WEEKLY-NORMAL';
          break;
        case 4:
          this.selectedNoteType = 'MN-WEEKLY-ABNORMAL';
          break;
        case 5:
          this.selectedNoteType = 'MN-MONTHLY';
          break;
        case 6:
          this.selectedNoteType = 'MN-GENERAL';
          break;
        case 7:
          this.selectedNoteType = 'MN-3RDS';
          break;
        case 8:
          this.selectedNoteType = 'MN-DRUGS';
          break;
        case 9:
          this.selectedNoteType = 'MN-HOSPITAL';
          break;
        case 10:
          this.selectedNoteType = 'MN-NEW-RPM';
          break;
      }

      const organizationId = this.userDetails.organizationId;
      const medicalNoteCategoryName = this.medicalNoteCategoryName;
      const medicalNoteCategoryType = this.selectedNoteType;
      const medicalNoteCategoryDescription = this.medicalNoteCategoryDescription;
      const billingSecondsForThisMedicalNoteCategory = totalSeconds;

      this.selectedHospitalIds.forEach((hospital) => {
        const officeId = hospital.id; // Assuming the hospital object has an id field
        const body = {
          organizationId: organizationId,
          officeId: officeId,
          medicalNoteCategoryName: medicalNoteCategoryName,
          medicalNoteCategoryType: medicalNoteCategoryType,
          medicalNoteCategoryDescription: medicalNoteCategoryDescription,
          billingSecondsForThisMedicalNoteCategory: billingSecondsForThisMedicalNoteCategory,
        };
        this.$store
            .dispatch('medicalnotecategory/addmedicalnotecategorytemplate', body)
            .then((res) => {
              this.selectedHospital = null;
              this.medicalNoteCategoryName = null;
              this.medicalNoteCategoryDescription = null;
              this.billingsTime = null;
              this.selectedNoteType = '';
              this.getCategoryMedicalNotesTemplates();
            });
      });
      // We don't need to have this , after the post , we are calling the GET API.
      // With this the client is waiting 1 second before getting the api Call , so additional seconds after the api has been generated....

      // setTimeout(() => {
      //   this.getCategoryMedicalNotesTemplates();
      // }, 1000);

      this.closeDrawer();
      this.selectedHospitalIds = [];
    },
    async closeDrawer () {
      // Uncheck all checkboxes
      this.hospitals.forEach((hospital) => {
        hospital.selected = false;
      });
      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    async getHospitals () {
      // Fixed the loading in the v-data-table, didn't had initialized the value.
      this.loading = true;
      await this.$store.dispatch('hospitals/getHospitals').then((res) => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
th{
  background-color:#ECEAF3 !important;
}
</style>
